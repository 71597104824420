import React,{ useEffect, useState} from "react";
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DownOutlined } from '@ant-design/icons';

import MenuHeader from '../components/MenuHeader';
import VideoPlayer from '../components/VideoPlayer';
import HomeHeader from '../components/HomeHeader';
import HomeServices from '../components/HomeServices';
import HomeAboutUs from '../components/HomeAboutUs';
import HomeClientCases from '../components/HomeClientCases';
// import HomeFaqs from '../components/HomeFaqs';
import BlockContact from '../components/BlockContact';
import BlockFooter from '../components/BlockFooter';

const HomePage = ({loading}) => {

    const { t } = useTranslation();
    
    const { pathname } = useLocation();    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [pathname]);

    const contactNav = () => {                
        setTimeout(() => {
          const element = document.getElementById('contact');
          if (element) {                    
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 100; // Adjust for the offset
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }, 0);
    };    

    return(
        <div>
            <Helmet>
                <title>Actin Pacific HK Ltd. | {t('meta.HomePage.title')}</title>
                <meta name="description" content={t('meta.HomePage.description')} />
                <meta name="keywords" content={t('meta.HomePage.keywords')} />
            </Helmet>
            <MenuHeader/>
            {loading && 
                <Spin className="spinner"/>
            }
            {!loading &&             
                <div className="page-container">                    
                    <VideoPlayer section="home"/> 
                    <div className="content-container">   
                        <HomeHeader/>
                        <div className="arrow-container">
                            <div className="arrow bounce">
                                <DownOutlined style={{fontSize:'22px'}}/>
                            </div>
                        </div>
                        <div onClick={contactNav} className={'home-mobile menu-item menu-button link'} >{t('menu.contact')}</div>
                        <HomeServices/>     
                        <HomeClientCases/>
                        <HomeAboutUs/>    
                        {/* <HomeFaqs/> */}
                        <BlockContact/>
                        <BlockFooter/>
                    </div>
                </div>
            }
        </div>
    );
};

export default HomePage;