import axios from 'axios';
  
// Create STRAPI Client

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_ACTIN_STRAPI_API_URL + '/api',
    headers: {
      'Content-Type': 'application/json', 
      'Accept-Language' : process.env.REACT_APP_DEFAULT_LANG,
      'Authorization' : `Bearer ${process.env.REACT_APP_ACTIN_STRAPI_API_TOKEN}`
    },   
});    

// List Section Data

export const ListStrapiSection = async (section, lang, options = {}) => {
    try {        
        const url = section + '?locale='+lang+process.env.REACT_APP_ACTIN_STRAPI_API_PARAMS;
        const response = await apiClient.get(url, {}, options);
        if (response && response.data && response.data.data) {
          return response.data.data;
        }
        return [];
    } catch (error) {
        console.error('API ListStrapiSection POST Error',error);
    }
};

const apiClientContact = axios.create({
  baseURL: process.env.REACT_APP_ACTIN_STRAPI_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json', 
    'Accept-Language' : process.env.REACT_APP_DEFAULT_LANG,
    'Authorization' : `Bearer ${process.env.REACT_APP_ACTIN_STRAPI_CONTACT_API_TOKEN}`
  },   
});    

export const SendContact = async (props) =>{
  try {
    return await apiClientContact.post('contacts', {data : props});
  } catch (error) {
    console.error('API SendContact POST Error',error);
  }
};