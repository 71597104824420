import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from './en.json';
import frTranslations from './fr.json';

// the translations

const resources = {
  en: {
    translation: enTranslations
  },
  fr: {
    translation: frTranslations
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_DEFAULT_LANG, 
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;