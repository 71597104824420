
import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const BlockFooter = ({}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const storedFooter = useSelector(state => state.footer ? state.footer.value : null);

    const linkMe = (link) =>{
        navigate(link);
    };


    const menuItems = [
        {            
            label : t('menu.home'),
            link : '/'
        },
        {
            label : t('menu.services'),
            link : '/services'
        },    
        {
            label : t('menu.clientCases'),
            link : '/client-cases'
        },    
        {
            label : t('menu.aboutUs'),
            link : '/about-us'
        },  
        {
            button:true,
            label : t('menu.contact'),
            link : '#contact'
        },                                
    ];

    return(
        <div className='foot-card'>    
            <div className='home-block-container footer'>
                <div className='flex-row-top'>
                    <div className='footer-block'>
                        <img src='/img/logo.png' style={{marginLeft:'32px'}} className='link logo-big' title='Actin Pacific HK Ltd' alt='Actin Pacific HK Ltd'/>
                        <div style={{marginLeft:'45px',padding:'16px 0'}}>
                            {menuItems && menuItems.map((menuItem,midx)=>
                                <div key={midx.toString()} style={{margin:'16px 0'}} className='link' onClick={()=>linkMe(menuItem.link)}>{menuItem.label}</div>
                            )}
                        </div>
                    </div>
                {storedFooter[0] && storedFooter[0].attributes && 
                    <div className='footer-block'>
                        <BlocksRenderer content={storedFooter[0].attributes.leftColumn} />
                    </div>                    
                }    
                {storedFooter[0] && storedFooter[0].attributes && 
                    <div className='footer-block'>
                        <BlocksRenderer content={storedFooter[0].attributes.rightColumn} />
                    </div>                        
                }    
                </div>
            </div>        
        </div>
    );

};

export default BlockFooter;