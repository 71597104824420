import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const videos = [
  // require('../assets/videos/1-AdobeStock_283958817.mov'),
  // require('../assets/videos/2-AdobeStock_410061845.mov'),
  require('../assets/videos/3-AdobeStock_463977120.mov'),
  require('../assets/videos/4-AdobeStock_410061845.mov'),
  require('../assets/videos/5-AdobeStock_521977768.mov'),
];
const mainVideo = require('../assets/videos/Video_Actin_Global.mp4');

const mobileBackground = require('../assets/images/mobile-bg.png')

const VideoPlayer = ({section}) => {

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const sectionVideo = {
    home:0,
    service:1,
    clientCases:0,
    aboutUs:2
  }

  const [currentVideoIndex, setCurrentVideoIndex] = useState(sectionVideo[section]);
  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    if (section === 'home'){
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }    
  };

  useEffect(() => {
    if (videoRef.current) {
    //   videoRef.current.play();
    videoRef.current.play().catch(error => {
        console.error('Error attempting to play', error);
      });
    };
    
  }, [currentVideoIndex]);

  return (
    <div style={styles.container}>
      {isMobile &&
        <img src={mobileBackground} style={styles.video} className='mobile-player' alt="Actin Pacific HK ltd."/>
      }
      {!isMobile && section === 'home' &&
        <video
          ref={videoRef}
          src={mainVideo}
          style={styles.video}
          onEnded={handleVideoEnded}
          // controls
          muted        
          autoPlay
          loop
          className='video-player'
        />
      }         
      {!isMobile && section !== 'home' &&
        <video
          ref={videoRef}
          src={videos[currentVideoIndex]}
          style={styles.video}
          onEnded={handleVideoEnded}
          // controls
          muted        
          autoPlay
          loop
          className='video-player'
        />
      }      
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    zIndex:0,
  },
  video: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default VideoPlayer;
