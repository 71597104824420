import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { CheckOutlined,DollarOutlined,TruckOutlined, EyeOutlined } from '@ant-design/icons';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Helmet } from 'react-helmet';

import MenuHeader from "../components/MenuHeader";
import BlockContact from '../components/BlockContact';
import BlockFooter from '../components/BlockFooter';
import BreadCrumd from '../components/BreadCrumd';
import VideoPlayer from '../components/VideoPlayer';
// import HomeFaqs from '../components/HomeFaqs';

const AboutUsPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    
    const storedAboutUs = useSelector(state => state.aboutUs  ? state.aboutUs.value[0] : {});
    
    const [content, setContent] = useState(storedAboutUs && storedAboutUs.attributes ? storedAboutUs.attributes?.detailDescription : []); 
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location.pathname]);

    useEffect(() => {
        setContent(storedAboutUs && storedAboutUs.attributes ? storedAboutUs.attributes?.detailDescription : []);
    }, [storedAboutUs]);

    return(
        <div>
            <Helmet>
                <title>Actin Pacific HK Ltd. | {t('meta.AboutUs.title')}</title>
                <meta name="description" content={t('meta.AboutUs.description')} />
                <meta name="keywords" content={t('meta.AboutUs.keywords')} />
            </Helmet>
            <MenuHeader/>
            <div className="page-container">
                <VideoPlayer section="aboutUs"/>                        
                <div className="content-container">   
                    <div className='page-block'>
                        <BreadCrumd section='aboutUs'/>
                        <div className='page-block-container'>
                            <div className='h1'>{t('menu.aboutUs')}</div>
                            <BlocksRenderer content={content} />
                        </div>              
                        {/* <HomeFaqs/> */}
                    </div>
                    <BlockContact/>
                    <BlockFooter/>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPage;