import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,useLocation,useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Carousel } from 'antd';
import { CheckOutlined,DollarOutlined,TruckOutlined, EyeOutlined } from '@ant-design/icons';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Helmet } from 'react-helmet';

import { normalizeTitle } from '../utils/Helpers';

import MenuHeader from "../components/MenuHeader";
import BlockContact from '../components/BlockContact';
import BlockFooter from '../components/BlockFooter';
import BreadCrumd from '../components/BreadCrumd';
import VideoPlayer from '../components/VideoPlayer';
// import HomeFaqs from '../components/HomeFaqs';

const ClientCaseDetailPage = () => {    

    const { t } = useTranslation();
    //const navigate = useNavigate();
    const location = useLocation();
    
    const storedClientCases = useSelector(state => state.clientCases  ? state.clientCases.value : null);
    
    const { title } = useParams();
    const clientCase = storedClientCases.find((clientCase,index)=>{
        return normalizeTitle(clientCase.attributes.title) === title;
    });

    //if (!clientCase) navigate('/client-cases');

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location.pathname]);
        
    let imageArray = [];
    if  (clientCase && clientCase.attributes?.images && clientCase.attributes?.images.data && clientCase.attributes?.images.data.length) {        
        imageArray = clientCase?.attributes?.images.data.map((imgData)=>{
            return process.env.REACT_APP_ACTIN_STRAPI_API_URL + imgData.attributes.url;
        });
    }
    return(
        <div>
            <Helmet>
                <title>Actin Pacific HK Ltd. | {t('meta.ClientCases.title')}</title>
                <meta name="description" content={t('meta.ClientCases.description')} />
                <meta name="keywords" content={t('meta.ClientCases.keywords')} />
            </Helmet>            
            <MenuHeader/>
            <div className="page-container">          
                <VideoPlayer section="clientCases"/>   
                <div className="content-container">   
                    <div className='page-block'>
                        <BreadCrumd section='clientCases' title={clientCase?.attributes?.title}/>                                                                            
                        <div className='page-block-container detail'>
                            <Carousel 
                                autoplay={true} 
                                autoplaySpeed={3000}
                                arrows={true}
                                >
                                {imageArray.length > 0 && imageArray.map((src, index) => (
                                    <div key={index}>
                                        <img src={src} alt={`Slide ${index}`} className='client-cases-detail-img' />
                                    </div>
                                ))}
                            </Carousel>
                            {/* <img src={imgUrl} alt='-' className='client-cases-detail-img'/> */}
                            <div className='client-case-detail-block'>
                                <div className='h1'>{clientCase?.attributes?.title}</div>    
                                <div className='h3 white' style={{padding:'16px 0'}}>{clientCase?.attributes?.tagline}</div>                       
                                <div className='h3'>{clientCase?.attributes?.sector}</div>
                                {clientCase && 
                                    <BlocksRenderer content={clientCase.attributes?.detailDescription} />
                                }                                
                            </div>
                        </div>              
                        {/* <HomeFaqs/> */}
                    </div>
                    <BlockContact/>
                    <BlockFooter/>
                </div>
            </div>
        </div>
    );
};

export default ClientCaseDetailPage;