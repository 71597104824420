
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
};

export const clientCasesSlice = createSlice({
  name: 'clientCases',
  initialState: initialState,
  reducers: {
    saveClienCases: (state, action) => {      
        state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveClienCases } = clientCasesSlice.actions

export default clientCasesSlice.reducer