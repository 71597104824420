
import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const HomeHeader = ({}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const storedHeader = useSelector(state => state.header ? state.header.value : null);

    const linkMe = (link) =>{
        //navigate(link);
    };

    return(
        <div className='home-header-block'>
            <div className='home-header-block-container header'>
                <div className='h2'>{storedHeader ? storedHeader[0]?.attributes?.tagline : ''}</div>
                {storedHeader && storedHeader[0] && storedHeader[0].attributes && 
                    <BlocksRenderer content={storedHeader[0].attributes.description} />
                }
                <div className='flex-row-end' style={{ width:'100%'}}>
                    <div className='link' style={{justifyContent: 'flex-end'}} onClick={()=>navigate('/about-us')}>...{t('more')}</div>     
                </div>                       
            </div>            
        </div>
    );

};

export default HomeHeader;