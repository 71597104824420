import { configureStore } from '@reduxjs/toolkit'
import servicesReducer from '../features/servicesSlice'
import aboutUsReducer from '../features/aboutUsSlice'
import clientCasessReducer from '../features/clientCasesSlice'
import faqsReducer from '../features/faqsSlice'
import headerReducer from '../features/headerSlice'
import footerReducer from '../features/footerSlice'

export default configureStore({
    reducer: {
        header: headerReducer,
        services: servicesReducer,
        aboutUs : aboutUsReducer,
        clientCases : clientCasessReducer,
        faqs: faqsReducer,
        footer:footerReducer,
    },
  })