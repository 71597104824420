import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { normalizeTitle } from '../utils/Helpers';
import { Helmet } from 'react-helmet';

import { BlocksRenderer } from '@strapi/blocks-react-renderer';

import MenuHeader from "../components/MenuHeader";
import BlockContact from '../components/BlockContact';
import BlockFooter from '../components/BlockFooter';
import BreadCrumd from '../components/BreadCrumd';
import VideoPlayer from '../components/VideoPlayer';
// import HomeFaqs from '../components/HomeFaqs'; 

const ClientCaseListPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    
    const storedClientCases = useSelector(state => state.clientCases  ? state.clientCases.value : null);
    
    const [items, setItems] = useState(storedClientCases || []);

    useEffect(() => {        
        const sortedItems = [...storedClientCases].sort((a, b) => {
          return parseInt(a.attributes.position) - parseInt(b.attributes.position);
        });
        setItems(sortedItems);
    }, [storedClientCases]);

    useEffect(() => {
        const sortedItems = [...storedClientCases].sort((a, b) => {
            return parseInt(a.attributes.position) - parseInt(b.attributes.position);
          });
          setItems(sortedItems);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });        
    }, [location.pathname]);

    const linkMe = (clientCase) =>{
        const title = clientCase.attributes.title;
        navigate('/client-case/' + normalizeTitle(title));
    };

    return(
        <div>
            <Helmet>
                <title>Actin Pacific HK Ltd. | {t('meta.ClientCases.title')}</title>
                <meta name="description" content={t('meta.ClientCases.description')} />
                <meta name="keywords" content={t('meta.ClientCases.keywords')} />
            </Helmet>                        
            <MenuHeader/>
            <div className="page-container">     
                <VideoPlayer section="clientCases"/>
                <div className="content-container">   
                    <div className='page-block'>
                        <BreadCrumd section='clientCases'/>
                        <div className='page-block-container'>                            
                            {items && items.length > 0 && items.map((clientCase,index)=> {
                                const imgUrl = clientCase.attributes?.listImage && clientCase.attributes?.listImage.data && clientCase.attributes?.listImage.data.attributes ? process.env.REACT_APP_ACTIN_STRAPI_API_URL + clientCase.attributes?.listImage.data.attributes.url : null;                                
                                return(
                                    <div key={index.toString()} className={index % 2 !== 0 ? 'flex-row flex-reverse client-case mobile' : 'flex-row client-case  mobile'} style={{marginBottom: '16px'}}>
                                        <div className='client-case-list-block'>
                                            <div className='h3 bold link'  onClick={()=>linkMe(clientCase)}>{clientCase.attributes?.title}</div>
                                            <div className='h5 bold' style={{marginTop:'16px'}}>{clientCase.attributes?.tagline}</div>
                                            <div className='h4 '>{clientCase.attributes?.sector}</div>                                        
                                            <BlocksRenderer content={clientCase.attributes?.listDescription} />                                   
                                            <div className='flex link' style={{ justifyContent: 'flex-end'}} onClick={()=>linkMe(clientCase)}>...{t('more')}</div>
                                        </div>
                                        <img src={imgUrl} alt='-' className='client-cases-list-img'  onClick={()=>linkMe(clientCase)}/>
                                    </div>
                                )
                            })}
                        </div>  
                        {/* <HomeFaqs/> */}
                    </div> 
                    <BlockContact/>
                    <BlockFooter/> 
                </div>                
            </div>
        </div>
    );
};

export default ClientCaseListPage;