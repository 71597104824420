import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const BreadCrumd = ({section,title=null}) => {    

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const linkMe = (page) =>{
        switch(page){
            case 'home' :
                return navigate('/');            
            case 'services' :
                return navigate('/services');
            case 'clientCases' :
                return navigate('/client-cases');         
            case 'aboutUs' :
                return navigate('/about-us');                                                
            default :
                return false;
        }        
    };

    return(
        <div className='breadcrumb'>
            <div className='link' onClick={()=>linkMe('home')}>{t('menu.home')}</div>
            {section &&                
                <div className='breadcrumb-separator'>></div>
            }            
            {section && !title && 
                <div className='link breadcrumb-title' onClick={()=>linkMe(section)}>{t('menu.'+section)}</div>
            }
            {section && title &&           
                <div className='link' onClick={()=>linkMe(section)}>{t('menu.'+section)}</div>
            }            
            {title &&                
                <div className='breadcrumb-separator'>></div>
            }              
            {title &&
                <div className='breadcrumb-title'>{title}</div>
            }            
        </div>
    );
}

export default BreadCrumd;