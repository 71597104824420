import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { normalizeTitle } from '../utils/Helpers';

import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Helmet } from 'react-helmet';
import MenuHeader from "../components/MenuHeader";
import ServiceIcon from '../components/ServiceIcon';
import BlockContact from '../components/BlockContact';
import BlockFooter from '../components/BlockFooter';
import BreadCrumd from '../components/BreadCrumd';
import VideoPlayer from '../components/VideoPlayer';
// import HomeFaqs from '../components/HomeFaqs';

const ServiceListPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    
    const storedServices = useSelector(state => state.services  ? state.services.value : null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location.pathname]);

    const linkMe = (service) =>{
        const title = service.attributes.title;
        navigate('/service/' + normalizeTitle(title));
    };

    return(
        <div>
            <Helmet>
                <title>Actin Pacific HK Ltd. | {t('meta.Services.title')}</title>
                <meta name="description" content={t('meta.Services.description')} />
                <meta name="keywords" content={t('meta.Services.keywords')} />
            </Helmet>              
            <MenuHeader/>
            <div className="page-container">
                <VideoPlayer section="service"/> 
                <div className="content-container">   
                    <div className='page-block'>
                        <BreadCrumd section='services'/>
                        <div className='page-block-container' >                            
                            {storedServices && storedServices.length > 0 && storedServices.map((service,index)=>
                                <div key={index.toString()} className='flex-colum  service-container' style={{marginBottom: '16px'}}>
                                    <div className='link flex-row-start service-block' onClick={()=>linkMe(service)}>
                                        <div className='service-icon flex-center'><ServiceIcon name={service.attributes?.title} size={{width:'200px',height:'200px'}} icon={service.attributes?.icon}/></div>
                                        <div style={{marginLeft:'32px', textAlign: 'left'}}>
                                            <div className='h3 bold'>{service.attributes?.title}</div>
                                            <BlocksRenderer content={service.attributes?.listDescription} />
                                        </div>
                                        
                                    </div>                                    
                                    <div className='flex link' style={{ justifyContent: 'flex-end'}} onClick={()=>linkMe(service)}>...{t('more')}</div>
                                </div>
                            )}
                        </div>  
                        {/* <HomeFaqs/>                         */}
                    </div>  
                    <BlockContact/>
                    <BlockFooter/>
                </div>                
            </div>
        </div>
    );
};

export default ServiceListPage;