
import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Carousel} from 'antd';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { normalizeTitle } from '../utils/Helpers';

const HomeClientCases = ({}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const storedClientCases = useSelector(state => state.clientCases  ? state.clientCases.value : null);
    const [items, setItems] = useState(storedClientCases || []);
    
    useEffect(() => {
        const sortedItems = [...items].sort((a, b) => {
          return parseInt(a.attributes.position) - parseInt(b.attributes.position);
        });
        setItems(sortedItems);
    }, []);
    
    const linkMe = (clientCase) =>{
        const title = clientCase.attributes.title;
        navigate('/client-case/' + normalizeTitle(title));
    };

    return(
        <div className='home-card'>
            <div className='home-card-container'>
                <div className='h2'>{t('home.clientCases')}</div>
                <div className='home-block-content mosaic-grid-2'>
                    {items && items.length > 0 && items.map((clientCase,index)=>{                        
                        const imgUrl = clientCase.attributes?.homeImage && clientCase.attributes?.homeImage.data && clientCase.attributes?.homeImage.data.attributes ? process.env.REACT_APP_ACTIN_STRAPI_API_URL + clientCase.attributes?.homeImage.data.attributes.url : null;                        
                        return (
                            <div className='flex-column-start link' style={{marginBottom:'16px'}} key={index.toString()}  onClick={()=>linkMe(clientCase)} title={t('more')}>
                                <img src={imgUrl} alt='-' className='client-cases-home-img'  onClick={()=>linkMe(clientCase)}/>
                                <div className='link h3 white' style={{ textAlign: 'center',marginTop:'16px'}}>{clientCase.attributes?.title}</div>                                
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );

};

export default HomeClientCases;