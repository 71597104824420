import React from 'react';
import { CheckOutlined,DollarOutlined,TruckOutlined, EyeOutlined } from '@ant-design/icons';

import ControlLogo from '../assets/icons/control.svg';
import ProductionLogo from '../assets/icons/production.svg';
import PurchaseLogo from '../assets/icons/purchase.svg';
import ResourcesLogo from '../assets/icons/resources.svg';
import SourcingLogo from '../assets/icons/sourcing.svg';
import TransportLogo from '../assets/icons/transport.svg';

const ServiceIcon = ({name, icon, size}) => {    
    switch(icon){        
        case 'control':
            return <img src={ControlLogo} alt={name} title={name} style={size}/>
        case 'production':
            return <img src={ProductionLogo} alt={name} title={name} style={size}/>
        case 'purchase':
            return <img src={PurchaseLogo} alt={name} title={name} style={size}/>
        case 'resources':
            return <img src={ResourcesLogo} alt={name} title={name} style={size}/>
        case 'sourcing':
            return <img src={SourcingLogo} alt={name} title={name} style={size}/>
        case 'transport':
            return <img src={TransportLogo} alt={name} title={name} style={size}/>            
        default:
            return false;
    };
}

export default ServiceIcon;