
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
};

export const faqsSlice = createSlice({
  name: 'faqs',
  initialState: initialState,
  reducers: {
    saveFaqs: (state, action) => {      
        state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveFaqs } = faqsSlice.actions

export default faqsSlice.reducer