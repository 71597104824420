import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,useLocation,useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import { CheckOutlined,DollarOutlined,TruckOutlined, EyeOutlined } from '@ant-design/icons';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { normalizeTitle } from '../utils/Helpers';
import { Helmet } from 'react-helmet';
import MenuHeader from "../components/MenuHeader";
import ServiceIcon from '../components/ServiceIcon';
import BlockContact from '../components/BlockContact';
import BlockFooter from '../components/BlockFooter';
import BreadCrumd from '../components/BreadCrumd';
import VideoPlayer from '../components/VideoPlayer';
// import HomeFaqs from '../components/HomeFaqs';

const ServiceDetailPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    
    const storedServices = useSelector(state => state.services  ? state.services.value : null);
    
    const { title } = useParams();
    let foundIndex = -1;
    const service = storedServices.find((service, index) => {
        if (normalizeTitle(service.attributes.title) === title) {
            foundIndex = index;
            return true;
        }
        return false;
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location.pathname]);

    const linkMe = (service) =>{
        if (!service) return navigate('/services');
        const title = service.attributes.title;
        navigate('/service/' + normalizeTitle(title));
    };

    return(
        <div>
            <Helmet>
                <title>Actin Pacific HK Ltd. | {t('meta.Services.title')}</title>
                <meta name="description" content={t('meta.Services.description')} />
                <meta name="keywords" content={t('meta.Services.keywords')} />
            </Helmet>                        
            <MenuHeader/>
            <div className="page-container">     
                <VideoPlayer section="service"/>             
                <div className="content-container">   
                    <div className='page-block'>
                        <BreadCrumd section='services' title={service?.attributes?.title}/>
                        <div className='page-block-container'>                            
                            <div className='flex-row-start'>
                                <div className='service-icon flex-center'><ServiceIcon name={service?.attributes?.title} size={{width:'200px',height:'200px'}} icon={service?.attributes?.icon}/></div>
                                <div style={{marginLeft:'32px'}} className='margin-mobile h1'>{service?.attributes?.title}</div>
                            </div>
                            {service && 
                                <BlocksRenderer content={service.attributes?.detailDescription} />                                
                            } 
                            <div className='flex flex-row service-pager' style={{padding:'16px 0'}}>
                                {storedServices[foundIndex-1] && 
                                    <div className='h3l lefter link unbold' onClick={()=>linkMe(storedServices[foundIndex-1])}>{'< '+storedServices[foundIndex-1].attributes?.title} </div>
                                }   
                                {!storedServices[foundIndex-1] && 
                                    <div className='h3l link unbold' />
                                }                                              
                                <div className='h3l link' onClick={()=>linkMe()}>{t('services.allServices')}</div>
                                {storedServices[foundIndex+1] && 
                                    <div className='h3l righter link' onClick={()=>linkMe(storedServices[foundIndex+1])}>{storedServices[foundIndex+1].attributes?.title+' >'}</div>
                                }
                                {!storedServices[foundIndex+1] && 
                                    <div className='h3l link unbold' />
                                }                                        
                            </div>
                        </div>              
                        {/* <HomeFaqs/>*/}
                    </div>
                    <BlockContact/>
                    <BlockFooter/>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetailPage;