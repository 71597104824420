import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Routes,  Route, useLocation } from 'react-router-dom';
import './App.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ListStrapiSection } from './services/Api';
import { saveServices } from './features/servicesSlice';
import { saveAboutUs } from './features/aboutUsSlice';
import { saveClienCases } from './features/clientCasesSlice';
import { saveFaqs } from './features/faqsSlice';
import { saveHeader } from './features/headerSlice';
import { saveFooter } from './features/footerSlice';

// import useScrollToTop from './hooks/ScrollHook';

import HomePage from './screens/HomePage';
import ServiceDetailPage from './screens/ServiceDetailPage';
import ServiceListPage from './screens/ServiceListPage';
import AboutUsPage from './screens/AboutUsPage';
import ClientCaseListPage from './screens/ClientCaseListPage'
import ClientCaseDetailPage from './screens/ClientCaseDetailPage'

function App() {

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    
    const bootStrap = async () =>{
      setLoading(true);
      console.log('bootStrap',i18n.language);
      const services = await ListStrapiSection('services',i18n.language);
      dispatch(saveServices(services));
      const aboutUs = await ListStrapiSection('aboutuses',i18n.language);
      dispatch(saveAboutUs(aboutUs));
      const clientCases = await ListStrapiSection('clientcases',i18n.language);
      dispatch(saveClienCases(clientCases));
      // const faqs = await ListStrapiSection('faqs',i18n.language);
      // dispatch(saveFaqs(faqs));
      const header = await ListStrapiSection('homes',i18n.language);
      dispatch(saveHeader(header));      
      const footer = await ListStrapiSection('footers',i18n.language);
      dispatch(saveFooter(footer));
      setLoading(false);                  
    };

    bootStrap();

  }, [i18n.language]);

  return (
    <div className="App">
      <Router>        
        <Routes>
          <Route path="/" element={<HomePage  loading={loading} />} />
          <Route path="/services" element={<ServiceListPage />} />
          <Route path="/service/detail" element={<ServiceDetailPage />} />
          <Route path="/service/:title" element={<ServiceDetailPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />      
          <Route path="/client-cases" element={<ClientCaseListPage />} />      
          <Route path="/client-case/:title" element={<ClientCaseDetailPage />} />          
        </Routes>      
      </Router>
    </div>
  );
}

export default App;
