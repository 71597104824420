
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input , notification } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { SendContact } from '../services/Api'

const { TextArea } = Input;

const BlockContact = () => {

    const { t } = useTranslation();

    const [name, setName] = useState(''); 
    const [companyName, setCompanyName] = useState(''); 
    const [email, setEmail] = useState(''); 
    const [phone, setPhone] = useState(''); 
    const [body, setBody] = useState(''); 

    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const onChange = (e,field) =>{
        const value = e?.target?.value;   
        switch(field){
            case 'name' : 
                setName(value);  
            break;   
            case 'companyName' : 
                setCompanyName(value);  
            break;   
            case 'email' : 
                setEmail(value);  
            break;   
            case 'phone' : 
                setPhone(value);  
            break;     
            case 'body' : 
                setBody(value);  
            break;                                                      
            default:
        }
    };

    const sendContact = async () =>{

        if (name === '' || companyName === '' ||  email === '' || phone === '' || body === '') {
            return  notification.error({
                message: t('contact.fieldsRequired'),
                placement: 'topRight'
              });
        }

        if (!isValidEmail(email)) {
            return notification.error({
                message: t('contact.badEmail'),
                placement: 'topRight'
              });
        }

        const props = {
            name,
            companyName,
            email,
            phone,
            body
        }
        
        const sendResult = await SendContact(props);
        if (!sendResult) {
            notification.error({
                message: t('contact.errorSend'),
                placement: 'topRight'
              });
        } else {
            notification.success({
                message: t('contact.success'),
                placement: 'topRight'
              });
              setName('');  
              setCompanyName('');  
              setEmail('');  
              setPhone('');  
              setBody('');  
        }
        
    };

    return(
        <div className='foot-card contact'>
            <a name="contact" id="contact"/>
            <div className='home-card-container contact'>
                <div className='h2'>{t('home.contact')}</div>
                <div style={{marginTop:'16px'}}>
                    <div className='flex-row' style={{marginBottom:'16px'}}>
                        <div style={{width:'100%',marginRight:'32px'}}>
                            <span>{t('contact.name')}*</span>
                            <Input
                                placeholder={t('contact.name')}
                                name='name'
                                id='name'
                                value={name}
                                onChange={(e)=>onChange(e,'name')}
                                style={{marginTop:'6px',width:'100%'}}
                            />
                        </div>
                        <div style={{width:'100%'}}>
                            <span>{t('contact.companyName')}*</span>
                            <Input
                                placeholder={t('contact.companyName')}
                                name='companyName'
                                id='companyName'
                                value={companyName}
                                onChange={(e)=>onChange(e,'companyName')}
                                style={{marginTop:'6px',width:'100%'}}
                            />
                        </div>  
                     </div>
                     <div className='flex-row'  style={{marginBottom:'16px'}}>   
                        <div style={{width:'100%',marginRight:'32px'}}>
                            <span>{t('contact.email')}*</span>
                            <Input
                                placeholder={t('contact.email')}
                                name='email'
                                id='email'
                                value={email}
                                onChange={(e)=>onChange(e,'email')}
                                style={{marginTop:'6px',width:'100%'}}
                            />
                        </div>     
                        <div style={{width:'100%'}}>
                            <span>{t('contact.phone')}*</span>
                            <Input
                                placeholder={t('contact.phone')}
                                name='phone'
                                id='phone'
                                value={phone}
                                onChange={(e)=>onChange(e,'phone')}
                                style={{marginTop:'6px',width:'100%'}}
                            />
                        </div>                                                                     
                    </div>
                    <div className='flex-column-start'  style={{alignItems: 'flex-start',textAlign:'left',marginBottom:'16px'}}>
                        <span>{t('contact.body')}*</span>
                        <TextArea 
                                value={body}
                                onChange={(e)=>onChange(e,'body')}
                                rows={4} 
                                placeholder={t('contact.body')} 
                                style={{marginTop:'6px',width:'100%'}}
                            />
                    </div>
                    <div className='flex-row-end'  style={{marginBottom:'16px'}}>
                        <div className='flex-row-end link button-main' onClick={sendContact}>
                            <div className='h4'>{t('contact.send')}</div>
                            <SendOutlined style={{marginLeft:'10px'}} className='h4' />
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    );
};

export default BlockContact;