
import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { MenuOutlined } from '@ant-design/icons';

const MenuHeader = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [mobileMenuDisplay, setMobileMenuDisplay] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    };

    const toogleMenu = ()=>{
        setMobileMenuDisplay(!mobileMenuDisplay);
    };

    const linkMe = (link) =>{        
        setMobileMenuDisplay(false);
        if (link === 'contact') {            
            const handleNavigate = (path) => {                
                setTimeout(() => {
                  const element = document.getElementById(path);
                  if (element) {                    
                    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - 100; // Adjust for the offset
                    window.scrollTo({
                      top: offsetPosition,
                      behavior: 'smooth',
                    });
                  }
                }, 0);
              };              
              handleNavigate(link);
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            navigate(link);
        }        
    };

    const menuItems = [
        {            
            label : t('menu.home'),
            link : '/'
        },
        {
            label : t('menu.services'),
            link : '/services'
        },    
        {
            label : t('menu.clientCases'),
            link : '/client-cases'
        },    
        {
            label : t('menu.aboutUs'),
            link : '/about-us'
        },  
        {
            button:true,
            label : t('menu.contact'),
            link : 'contact'
        },                                
    ];

    return(
        <div className='menu-header'>
            <div className='menu-container'>
                <img src='/img/logo.png' style={{marginLeft:'32px'}} onClick={()=>navigate('/')} className='link logo' title='Actin Pacific HK Ltd' alt='Actin Pacific HK Ltd'/>
                <div className='menu-items'>
                    {menuItems && menuItems.map((menuItem,midx)=>
                        <div key={midx.toString()} className={menuItem.button ? 'menu-item menu-button' : 'menu-item link'} onClick={()=>linkMe(menuItem.link)}>{menuItem.label}</div>
                    )}
                </div>
                <div className='lang-switcher'>
                    {/* <div onClick={() => changeLanguage('en')} className={i18n.language === 'en' ? 'language-button link active' : 'language-button link'}>EN</div> */}
                    {/* <div onClick={() => changeLanguage('fr')} className={i18n.language === 'fr' ? 'language-button link active' : 'language-button link'}>FR</div> */}
                </div>    
                <div className='menu-mobile'>
                    <MenuOutlined onClick={toogleMenu}/>
                    <div className='menu-items' style={{display:mobileMenuDisplay ? 'inline' : 'none'}}>
                        {menuItems && menuItems.map((menuItem,midx)=>
                            <div key={midx.toString()} className={menuItem.button ? 'menu-item menu-button' : 'menu-item link'} onClick={()=>linkMe(menuItem.link)}>{menuItem.label}</div>
                        )}
                    </div>
                </div>
            </div>                   
        </div>
    )

};

export default MenuHeader;