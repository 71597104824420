
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState: initialState,
  reducers: {
    saveServices: (state, action) => {      
        state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveServices } = servicesSlice.actions

export default servicesSlice.reducer