
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
};

export const aboutUsSlice = createSlice({
  name: 'aboutUs',
  initialState: initialState,
  reducers: {
    saveAboutUs: (state, action) => {      
        state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveAboutUs } = aboutUsSlice.actions

export default aboutUsSlice.reducer