
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
};

export const headerSlice = createSlice({
  name: 'header',
  initialState: initialState,
  reducers: {
    saveHeader: (state, action) => {      
        state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveHeader } = headerSlice.actions

export default headerSlice.reducer