
import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const HomeAboutUs = ({}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const storedAboutUs = useSelector(state => state.aboutUs  ? state.aboutUs.value : null);

    const linkMe = () =>{
        navigate('/about-us');
    };    
    
    return(
        <div className='home-card'>
            <div className='home-card-container'>
                <div className='h2'>{t('home.aboutUs')}</div>
                {storedAboutUs &&  storedAboutUs[0] && 
                    <BlocksRenderer content={storedAboutUs &&  storedAboutUs[0] && storedAboutUs[0].attributes ? storedAboutUs[0].attributes?.homeDescription : []} />
                }      
                <div className='flex link' style={{ justifyContent: 'flex-end'}} onClick={linkMe}>...{t('more')}</div>
            </div>
        </div>
    );

};

export default HomeAboutUs;