
import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { normalizeTitle } from '../utils/Helpers';

import ServiceIcon from '../components/ServiceIcon';

const HomeServices = ({}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const storedServices = useSelector(state => state.services  ? state.services.value : null);

    const linkMe = (service) =>{
        const title = service.attributes.title;
        navigate('/service/' + normalizeTitle(title));
    };

    return(
        <div className='home-card'>
            <div className='home-card-container'>
                <div className='h2'>{t('home.services')}</div>
                <div className='home-block-content mosaic-grid-3'>
                    {storedServices && storedServices.length > 0 && storedServices.map((service,index)=>
                        <div key={index.toString()} className='link service-container flex-column' onClick={()=>linkMe(service)}>
                            <div className='service-icon flex-center'><ServiceIcon name={service.attributes?.title} size={{width:'200px',height:'200px'}} icon={service.attributes?.icon}/></div>
                            <div className='h3 white'>{service.attributes?.title}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

};

export default HomeServices;